import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";

const AdminsPage = () => {
    const [admins, setAdmins] = useState([]);
    const [showCreateInput, setShowCreateInput] = useState(false);
    const [newAdminFirstName, setNewAdminFirstName] = useState('');
    const [newAdminLastName, setNewAdminLastName] = useState('');
    const [newAdminEmail, setNewAdminEmail] = useState('');
    const [newAdminPassword, setNewAdminPassword] = useState('');
    const navigate = useNavigate()


    useEffect(() => {
        const fetchAdmins = async () => {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/getAll`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
            setAdmins(res.data);
        };

        fetchAdmins();
    }, []);

    const isPasswordValid = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);

        return (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumber
        );
    };

    const handleCreateAdmin = async () => {
        // Check if the name, email and password are provided
        if (!newAdminFirstName || !newAdminLastName || !newAdminEmail || !newAdminPassword) {
            toast.error('Please fill out all fields');
            return;
        }

        // Check if the email is valid
        if (!newAdminEmail.includes("@")) {
            toast.error("Please enter a valid email address");
            return;
        }

        // Check if the password is valid using the isPasswordValid function
        if (!isPasswordValid(newAdminPassword)) {
            toast.error("Your password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter and 1 number");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/admin/createAdmin`, { firstName: newAdminFirstName, lastName: newAdminLastName, email: newAdminEmail, password: newAdminPassword }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
            setAdmins([...admins, response.data]);
            setNewAdminFirstName('');
            setNewAdminLastName('');
            setNewAdminEmail('');
            setNewAdminPassword('');
            setShowCreateInput(false);
            toast.success('Admin created successfully');
        } catch (error) {
            toast.error('Error creating admin:', error);
        }
    };

    const handleDeleteAdmin = async (adminId) => {
        if (window.confirm("Are you sure you want to remove this admin?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/deleteAdmin/${adminId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` } });
                // Update categories state to remove the deleted category
                setAdmins(admins.filter(admin => admin._id !== adminId));
                toast.success('Admin deleted successfully');
            } catch (error) {
                toast.error('Error deleting category:', error);
            }
        }
    };

    return (
        <div className="lg:py-8 containerNavbar">
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/dashboard')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Admins</h1>
            <div>
                <button onClick={() => setShowCreateInput(!showCreateInput)} className={`transition duration-500 ease-in-out rounded max-sm:w-full mb-6 text-center ${showCreateInput ? 'bg-red-500 hover:bg-red-600 hover:text-white' : 'bg-primary hover:bg-primary-hover'} text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]`}>
                    {showCreateInput ? 'Cancelar' : 'Adicionar Admin'}
                </button>
            </div>
            {showCreateInput && (
                <div className="flex flex-col mt-2 space-y-4 xl:flex-row xl:space-y-0 xl:space-x-4">
                    <input
                        type="text"
                        value={newAdminFirstName}
                        onChange={(e) => setNewAdminFirstName(e.target.value)}
                        className="xl:w-1/5 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                        placeholder="First name"
                    />
                    <input
                        type="text"
                        value={newAdminLastName}
                        onChange={(e) => setNewAdminLastName(e.target.value)}
                        className="xl:w-1/5 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                        placeholder="Last name"
                    />
                    <input
                        type="email"
                        value={newAdminEmail}
                        onChange={(e) => setNewAdminEmail(e.target.value)}
                        className="xl:w-1/5 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                        placeholder="Email"
                    />
                    <input
                        type="password"
                        value={newAdminPassword}
                        onChange={(e) => setNewAdminPassword(e.target.value)}
                        className="xl:w-1/5 px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                        placeholder="Password"
                    />
                    <button onClick={handleCreateAdmin} className="xl:w-1/5 hover:bg-primary-hover transition duration-500 ease-in-out rounded text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 px-6 text-white hover:text-textHover tracking-[2px]">
                        Adicionar
                    </button>
                </div>
            )}

            <div className="my-10 overflow-x-auto rounded-[16px] border border-[#A892FF33]">
                <table className="table w-full bg-[#FFFFFF1A] table-auto">
                    <thead className="text-textHover bg-primary-hover">
                        <tr>
                            <th className="px-4 py-2 border-r border-primary">Nome</th>
                            <th className="px-4 py-2 border-r border-primary">Email</th>
                            <th className="px-4 py-2">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map((admin) => (
                            <tr key={admin._id} className="border border-[#A892FF33] text-secondary text-sm sm:text-base">
                                <td className="w-2/6 px-4 py-2 border-r border-[#A892FF33]">{admin.firstName} {admin.lastName}</td>
                                <td className="w-4/5 px-4 py-2 border-r border-[#A892FF33]">{admin.email}</td>
                                <td className="px-2 py-2">
                                    <button
                                        onClick={() => handleDeleteAdmin(admin._id)}
                                        className="px-4 py-2 text-sm font-bold text-white bg-red-500 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminsPage;