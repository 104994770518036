import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../context/language.context';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { BsPencilFill } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Loading from "../../components/Loading/Loading";
import { getData } from 'country-list';

function ProfilePage() {
  const { strings } = useContext(LanguageContext);
  const [changeProfile, setChangeProfile] = useState(false); //enables the user to make changes
  const [googleId, setGoogleId] = useState("");
  const [pictureChanged, setPictureChanged] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState(false);
  const [canChangePassword, setCanChangePassword] = useState(false); //checks if all the values on the modal change password are filled
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [billingName, setBillingName] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingTaxId, setBillingTaxId] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [changed, setChanged] = useState(false); //Check if there is changes in the profile
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const countries = getData();

  const token = localStorage.getItem('accessToken');
  const decodedToken = jwtDecode(token);

  useEffect(() => {
    fetchProfile()
  }, [changeProfile]);

  const isValidPortugueseNIF = (nif) => {
    if (!/^\d{9}$/.test(nif)) {
      return false;
    }

    const checkDigit = parseInt(nif[8], 10);
    const weights = [9, 8, 7, 6, 5, 4, 3, 2];
    const sum = nif.split('')
      .slice(0, 8)
      .reduce((acc, digit, idx) => acc + parseInt(digit, 10) * weights[idx], 0);

    const calculatedCheckDigit = 11 - (sum % 11);
    return calculatedCheckDigit === checkDigit || (calculatedCheckDigit === 10 && checkDigit === 0);
  };

  //Get user info
  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/getUser/${decodedToken._id}`, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } });
      setProfilePicture(response.data.profilePicture)
      setGoogleId(response.data.googleCredential)

      setFirstName(response.data.firstName)
      setLastName(response.data.lastName)
      setEmail(response.data.email)
      setBillingName(response.data.billingInfo?.billingName)
      setBillingCity(response.data.billingInfo?.billingCity)
      setBillingPostalCode(response.data.billingInfo?.billingPostalCode)
      setBillingAddress(response.data.billingInfo?.billingAddress)
      setBillingTaxId(response.data.billingInfo?.billingTaxId)
      setBillingCountry(response.data.billingInfo?.billingCountry)

    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //Check if the user made a changes on his info
  const checkChange = () => {
    if (firstName !== document.getElementById("firstName").value || lastName !== document.getElementById("lastName").value || email !== document.getElementById("email").value
      || billingName !== document.getElementById("billingName").value || billingCity !== document.getElementById("billingCity").value || billingPostalCode !== document.getElementById("billingPostalCode").value
      || billingAddress !== document.getElementById("billingAddress").value || billingTaxId !== document.getElementById("billingTaxId").value || billingCountry !== document.getElementById("billingCountry").value || pictureChanged === true) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }

  //Handle input errors and call the function saveChangesDB
  const toastSave = async () => {
    const billingTaxIdValue = document.getElementById("billingTaxId").value;

    if (document.getElementById("firstName").value === "" || document.getElementById("lastName").value === "" || document.getElementById("email").value === "") {
      toast.error(strings.profile.toastIncomplete);
      return;
    }

    // Validate Portuguese NIF before proceeding
    if (billingTaxIdValue && !isValidPortugueseNIF(billingTaxIdValue)) {
      toast.error("NIF inválido");
      return;
    }
    
    toast.promise(
      saveChangesDB(),
      {
        loading: <b>{strings.toast.toastLoading}</b>,
        success: <b>{strings.toast.toastSuccess}</b>,
        error: <b>{strings.toast.toastError}</b>,
      }
    );
  }

  //Save changes on the DB
  const saveChangesDB = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let image = profilePicture;


      // picture changed não é setado para true nunca o que causa erro e guarda a imagem como blob e nao url do cloudinary
      if (pictureChanged) {
        console.log("Foto alterou")
        image = document.getElementById("imageInput").files[0];
      }

      formData.append('firstName', document.getElementById("firstName").value);
      formData.append('lastName', document.getElementById("lastName").value);
      formData.append('email', document.getElementById("email").value);
      formData.append('image', image);
      formData.append('billingName', document.getElementById("billingName").value);
      formData.append('billingCity', document.getElementById("billingCity").value);
      formData.append('billingPostalCode', document.getElementById("billingPostalCode").value);
      formData.append('billingAddress', document.getElementById("billingAddress").value);
      formData.append('billingTaxId', document.getElementById("billingTaxId").value);
      formData.append('billingCountry', document.getElementById("billingCountry").value);

      axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/editUser/${decodedToken._id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          setChangeProfile(false);
          setPictureChanged(false);
          setChanged(false);
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  //Check if the user have all the fields filled
  const checkCanChangePassword = () => {
    if (document.getElementById("oldPassword").value && document.getElementById("newPassword").value && document.getElementById("newPasswordRepeat").value) {
      setCanChangePassword(true);
    } else {
      setCanChangePassword(false);
    }
  }

  //Handle input errors and call the function changePasswordDB
  const toastChangePassword = async () => {
    if (newPassword !== newPasswordRepeat) {
      toast.error(strings.changePassword.passwordsDontMatch);
      return;
    }

    toast.promise(
      changePasswordDB(),
      {
        loading: <b>{strings.toast.toastLoading}</b>,
        success: <b>{strings.toast.toastSuccess}</b>,
        error: (err) => handleErrorChangePassword(err),
      }
    );
  }

  // Save the new password on the DB
  const changePasswordDB = () => {
    return new Promise((resolve, reject) => {
      let formData = {
        "oldPassword": oldPassword,
        "newPassword": newPassword
      }

      axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/changePassword/${decodedToken._id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          resolve(res);
          closeModal()
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  //Handle difrent types of error messages on the function toastChangePassword
  const handleErrorChangePassword = (err) => {
    if (err.response.data.message === "Wrong old password") {
      return strings.changePassword.oldPasswordWrong;
    } else if (err.response.data.message === "Password not secure") {
      document.getElementById("passwordStrength").classList.remove("hidden");
      return strings.changePassword.passwordStrengthModal;
    } else {
      return strings.toast.toastError;
    }
  }

  //Close change password modal
  const closeModal = () => {
    document.getElementById("passwordStrength").classList.add("hidden");
    setModal(false);
    setOldPassword("");
    setNewPassword("");
    setNewPasswordRepeat("");
    setShowOldPassword(false)
    setShowNewPassword(false);
    setShowNewPasswordRepeat(false);
  }

  //Handle some inputs change
  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
    checkCanChangePassword();
  }

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    checkCanChangePassword();
  }

  const handleNewPasswordRepeat = (e) => {
    setNewPasswordRepeat(e.target.value);
    checkCanChangePassword();
  }

  const handleImageChange = (event) => {
    if (event) {
      setPictureChanged(true);
      setChanged(true);
      setProfilePicture(URL.createObjectURL(event.target.files[0]));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mb-20 lg:py-8 containerNavbar">
      <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">{strings.profile.profile}</h1>
      <div className="p-6 overflow-hidden bg-[#FFFFFF1A] rounded-[16px]">
        <div className="">
          <div className="flex items-center justify-center">
            {changeProfile ? <div className='relative transition cursor-pointer group hover:opacity-80'>
              <img
                className="object-cover border-2 rounded-full h-28 w-28 border-primary"
                src={profilePicture} onClick={() => { document.getElementById('imageInput').click(); }}
                alt="Profile"
              />
              <div className={`absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100`} >
                <BsPencilFill className="text-white" />
              </div>
            </div> : <img
              className="object-cover border-2 rounded-full h-28 w-28 border-primary"
              src={profilePicture}
              alt="Profile"
            />
            }
            <input className='hidden' id="imageInput" type="file" accept=".png, .jpeg, .jpg" onChange={handleImageChange} />
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:px-6 md:grid-cols-2">
          <div className='flex flex-col items-center'>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.name}*</h6>
              {changeProfile ?
                <input id='firstName' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={firstName} placeholder={strings.profile.name} onChange={checkChange} />
                : firstName
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{firstName}</p>
                  : <p className='w-full border inputField border-b-gray-400'>{firstName}</p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.surname}*</h6>
              {changeProfile ?
                <input id='lastName' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={lastName} placeholder={strings.profile.surname} onChange={checkChange} />
                : lastName
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{lastName}</p>
                  : <p className='w-full border inputField border-b-gray-400'>{lastName}</p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.email}*</h6>
              {changeProfile ?
                <input id='email' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={email} placeholder={strings.profile.email} onChange={checkChange} />
                : email
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{email}</p>
                  : <p className='w-full border inputField border-b-gray-400'>{email}</p>
              }
            </div>
            {!googleId &&
              <div className='flex justify-center w-full my-8 md:mb-2'>
                <button className='w-full sm:w-fit hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => setModal(true)}>{strings.changePassword.changePassword}</button>
              </div>
            }
          </div>
          <div className='flex flex-col items-center'>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingName}</h6>
              {changeProfile ?
                <input id='billingName' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={billingName} placeholder={strings.profile.billingName} onChange={checkChange} />
                : billingName
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingName}</p>
                  : <p className='w-full border-b h-11 border-b-gray-400'></p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingCity}</h6>
              {changeProfile ?
                <input id='billingCity' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={billingCity} placeholder={strings.profile.billingCity} onChange={checkChange} />
                : billingCity
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingCity}</p>
                  : <p className='w-full border-b h-11 border-b-gray-400'></p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingCountry}</h6>
              {changeProfile ? (
                <select
                  id='billingCountry'
                  className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1'
                  defaultValue={billingCountry}
                  onChange={checkChange}
                >
                  <option value="" className='text-black'>{strings.profile.billingCountry}</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code} className='text-black'>
                      {country.name}
                    </option>
                  ))}
                </select>
              ) : billingCountry ? (
                <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingCountry}</p>
              ) : (
                <p className='w-full border-b h-11 border-b-gray-400'></p>
              )}
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingPostalCode}</h6>
              {changeProfile ?
                <input id='billingPostalCode' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={billingPostalCode} placeholder={strings.profile.billingPostalCode} onChange={checkChange} />
                : billingPostalCode
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingPostalCode}</p>
                  : <p className='w-full border-b h-11 border-b-gray-400'></p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingAddress}</h6>
              {changeProfile ?
                <input id='billingAddress' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={billingAddress} placeholder={strings.profile.billingAddress} onChange={checkChange} />
                : billingAddress
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingAddress}</p>
                  : <p className='w-full border-b h-11 border-b-gray-400'></p>
              }
            </div>
            <div className='w-full my-2 sm:w-4/5'>
              <h6 className='text-sm font-bold text-white'>{strings.profile.billingTaxId}</h6>
              {changeProfile ?
                <input id='billingTaxId' className='w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1' defaultValue={billingTaxId} placeholder={strings.profile.billingTaxId} onChange={checkChange} />
                : billingTaxId
                  ? <p className='w-full px-3 py-3 text-sm text-white border-b border-b-gray-400'>{billingTaxId}</p>
                  : <p className='w-full border-b h-11 border-b-gray-400'></p>
              }
            </div>
          </div>
        </div>
        {
          changeProfile ?
            <div className='flex flex-col justify-center mt-2 sm:flex-row gap-x-10 gap-y-6'>
              <button className='sm:w-52 hover:bg-red-700 rounded transition duration-500 ease-in-out bg-red-500 text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white tracking-[2px]' onClick={() => { setChangeProfile(false); }}>{strings.profile.cancel}</button>
              {
                changed ?
                  <button className='sm:w-52 hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => toastSave()}>Salvar</button>
                  :
                  <button className='sm:w-52 rounded transition duration-500 ease-in-out bg-secondary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-black tracking-[2px]'>Salvar</button>
              }
            </div>
            :
            <div className='flex justify-center'>
              <button className='w-full sm:w-fit hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => setChangeProfile(true)}>{strings.profile.editProfile}</button>
            </div>
        }
      </div>
      <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center" overlayClassName="fixed inset-0 bg-[rgba(0,0,0,0.35)]">
        <div className="absolute w-full h-full"></div>
        <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-12 w-[90%] sm:w-[30rem] bg-[#1c1c32] py-7 rounded-[16px]">
          <div>
            <h1 className="text-lg font-bold text-white">{strings.changePassword.changePassword}</h1>
          </div>
          <div className='w-11/12 mt-5 space-y-3'>
            <div className='flex flex-col'>
              <p className='text-sm text-white'>{strings.changePassword.oldPassword}</p>
              <div className="relative flex">
                <input
                  id='oldPassword'
                  className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1"
                  type={showOldPassword ? "text" : "password"}
                  name="password"
                  value={oldPassword}
                  onChange={handleOldPassword}
                />
                <button
                  className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                  type="button"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                </button>
              </div>
            </div>
            <div className='flex flex-col mt-1'>
              <p className='text-sm text-white'>{strings.changePassword.newPassword}</p>
              <div className="relative flex">
                <input
                  id='newPassword'
                  className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1"
                  type={showNewPassword ? "text" : "password"}
                  name="password"
                  value={newPassword}
                  onChange={handleNewPassword}
                />
                <button
                  className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                </button>
              </div>
            </div>
            <div className='flex flex-col mt-1'>
              <p className='text-sm text-white'>{strings.changePassword.confirmNewPassword}</p>
              <div className="relative flex">
                <input
                  id='newPasswordRepeat'
                  className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit mt-1"
                  type={showNewPasswordRepeat ? "text" : "password"}
                  name="password"
                  value={newPasswordRepeat}
                  onChange={handleNewPasswordRepeat}
                />
                <button
                  className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                  type="button"
                  onClick={() => setShowNewPasswordRepeat(!showNewPasswordRepeat)}
                >
                  {showNewPasswordRepeat ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
                </button>
              </div>
            </div>
          </div>
          <div id='passwordStrength' className='hidden mt-1 text-red-500'>{strings.changePassword.passwordStrength}</div>
          <div className='mt-5'>
            {
              canChangePassword ?
                <button className='hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => toastChangePassword()}>{strings.changePassword.save}</button>
                :
                <button className='rounded transition duration-500 ease-in-out bg-secondary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-black tracking-[2px]'>{strings.changePassword.save}</button>
            }
          </div>
          <button className="absolute top-3 right-3" onClick={() => closeModal()}>
            <IoCloseSharp size={24} className="text-red-500 hover:text-red-700" />
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ProfilePage;