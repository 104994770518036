import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LanguageContext } from '../../context/language.context';
import { AuthContext } from "../../context/auth.context";
import { TfiWrite } from "react-icons/tfi";
import { FaCheck } from "react-icons/fa";

const MyCourseIndividualPage = () => {
    const { slug } = useParams();
    const { user } = useContext(AuthContext);
    const [course, setCourse] = useState(null);
    const [userData, setUserData] = useState(null);
    const [modules, setModules] = useState([]);
    const { strings } = useContext(LanguageContext);
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responseUser = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/getUser/${user?._id}`, { headers: { Authorization: `Bearer ${token}` } });
                setUserData(responseUser.data)
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        const fetchCourse = async () => {
            try {
                const responseCourse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/course/getCourseBySlug/${slug}`, { headers: { Authorization: `Bearer ${token}` } });
                setCourse(responseCourse.data);
                const fetchedModules = responseCourse.data.modules.length > 0 ? responseCourse.data.modules : [];
                setModules(fetchedModules);
            } catch (error) {
                console.error('Error fetching course:', error);
            }
        };
        fetchCourse();
        if (user?._id) fetchUser();
    }, [user?._id]);

    const isCoursePurchased = () => {
        if (!userData) return false;
        return userData.courses.some(courseData => courseData.courseId === course?._id);
    };

    useEffect(() => {
        if (userData && course && !isCoursePurchased()) {
            navigate(`/course/${slug}`);
        }
    }, [userData, course]);

    const isLessonCompleted = (lessonId) => {
        if (!userData) return false;

        const courseInfo = userData.courses.find(courseData => courseData.courseId === course?._id);
        if (!courseInfo) return false;

        const lesson = courseInfo.progress.find(lesson => lesson.lessonId === lessonId);
        return lesson ? lesson.watched : false;
    };


    return (
        <div className="py-6 mb-20 containerNavbar">
            {course && (
                <div className='grid w-full grid-cols-1 lg:grid-cols-4 lg:gap-x-8 gap-y-4'>
                    <div className='lg:col-span-3'>
                        <h1 className="mb-2 text-3xl font-bold text-white sm:text-4xl">{course?.title}</h1>
                        <p className="inline-flex items-center mb-8 text-xs font-light text-white sm:text-sm">
                            {strings.courses.course.aCourseOf}<span className='ml-1 font-semibold text-dionamiteOrange'>{course?.creator?.firstName} {course?.creator?.lastName}</span>
                        </p>

                        <div className='p-4 bg-[#FFFFFF1A] rounded-[16px]'>
                            {modules && modules.map((module, index) => (
                                <div key={module?._id}>
                                    <div className='p-2'>
                                        <div className='border-b border-gray-200'>
                                            <h2 className="pb-4 text-lg font-bold sm:text-xl text-secondary">M{index + 1}: {module?.name}</h2>
                                        </div>
                                        {module.lessons.map(lesson => (
                                            <div key={lesson?._id} onClick={() => navigate(`/mycourses/${slug}/lesson/${lesson?._id}`)} className='flex items-center my-4 ml-2 space-x-4 text-white cursor-pointer sm:ml-4 w-fit hover:text-gray-300' >
                                                {isLessonCompleted(lesson._id) ? (
                                                    <div className='flex items-center space-x-4 text-primary hover:text-textHover'>
                                                        <FaCheck className='flex-shrink-0' />
                                                        <h2 className="text-sm font-semibold sm:text-base">{lesson?.name}</h2>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <TfiWrite className='flex-shrink-0' />
                                                        <h2 className="text-sm font-semibold sm:text-base">{lesson?.name}</h2>
                                                    </>
                                                )}

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-span-1 mt-10'>
                        <button
                            className="hover:bg-primary-hover transition duration-500 ease-in-out rounded mb-10 text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                            onClick={() => navigate(`/course/${slug}`)}
                        >
                            Ver Detalhes do curso
                        </button>
                        <p className='text-lg font-bold text-white'>Índice</p>
                        {modules && modules.map((module, index) => (
                            <div key={module?._id}>
                                <div className='flex items-center mt-4 ml-2 space-x-3'>
                                    <p className='p-2 text-xs font-semibold bg-gray-300 rounded-full'>M{index + 1}</p>
                                    <h2 className="text-xs font-semibold text-white sm:text-sm">{module?.name}</h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MyCourseIndividualPage;