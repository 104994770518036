import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import { FaTrash } from 'react-icons/fa';
import { MdEdit } from "react-icons/md";
import Select from 'react-select'
import Modal from 'react-modal';

const CouponsPage = () => {
    const [coupons, setCoupons] = useState([]);
    const [courses, setCourses] = useState([]);
    const [newCouponName, setNewCouponName] = useState('');
    const [newCouponAvailability, setNewCouponAvailability] = useState(null);
    const [newCouponCourses, setNewCouponCourses] = useState([]);
    const [newCouponType, setNewCouponType] = useState(null);
    const [newCouponPrice, setNewCouponPrice] = useState('');
    const [newCouponPercentage, setNewCouponPercentage] = useState('');
    const [newCouponExpirationDate, setNewCouponExpirationDate] = useState('');
    const [newCouponUsageLimit, setNewCouponUsageLimit] = useState(null);
    const [modal, setModal] = useState(false);
    const [editingCoupon, setEditingCoupon] = useState(null);
    const navigate = useNavigate()

    const availabilityOptions = [
        { value: 'general', label: 'Geral' },
        { value: 'specific', label: 'Específico' }
    ];

    const typeOptions = [
        { value: 'price', label: 'Preço' },
        { value: 'percentage', label: 'Percentagem' }
    ];

    const usageLimitOptions = [
        { value: 'one-time', label: 'Uma vez por usuário' },
        { value: 'no-limit', label: 'Sem limite' }
    ];

    const getLabelFromOptions = (options, value) => {
        const option = options.find(opt => opt.value === value);
        return option ? option.label : '-';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [couponsRes, coursesRes] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/coupon/getAll`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/course/getAll`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
                ]);

                setCoupons(couponsRes.data);
                setCourses(coursesRes.data.map(course => ({ value: course._id, label: course.title })));
            } catch (error) {
                toast.error('Error fetching data');
            }
        };

        fetchData();
    }, []);

    const handleCreateOrUpdateCoupon = async () => {
        if (!newCouponName || !newCouponAvailability || !newCouponType || !newCouponExpirationDate) {
            toast.error('Please fill out all fields');
            return;
        }

        if (newCouponType.value === 'price' && !newCouponPrice) {
            toast.error('Please enter a valid price');
            return;
        }

        if (newCouponType.value === 'percentage' && !newCouponPercentage) {
            toast.error('Please fill out the percentage');
            return;
        }

        const couponData = {
            name: newCouponName,
            availability: newCouponAvailability.value,
            courses: newCouponAvailability.value === 'specific' ? newCouponCourses.map(course => course.value) : [],
            type: newCouponType.value,
            price: newCouponType.value === 'price' ? newCouponPrice : null,
            percentage: newCouponType.value === 'percentage' ? newCouponPercentage : null,
            expiration: newCouponExpirationDate,
            usageLimit: newCouponUsageLimit.value
        };

        try {
            if (editingCoupon) {
                await axios.patch(`${process.env.REACT_APP_SERVER_URL}/coupon/updateCoupon/${editingCoupon._id}`, couponData, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
                setCoupons(coupons.map(coupon => coupon._id === editingCoupon._id ? { ...coupon, ...couponData } : coupon));
                toast.success('Cupão atualizado com sucesso');
            } else {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/coupon/createCoupon`, couponData, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
                setCoupons([...coupons, response.data]);
                toast.success('Cupão adicionado com sucesso');
            }
            closeModal();
        } catch (error) {
            toast.error('Error creating/updating Coupon:', error);
        }
    };

    const handleDeleteCoupon = async (couponId) => {
        if (window.confirm("Tem a certeza que quer eliminar este cupão?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER_URL}/coupon/deleteCoupon/${couponId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` } });
                // Update categories state to remove the deleted category
                setCoupons(coupons.filter(coupon => coupon._id !== couponId));
                toast.success('Cupão eliminado com sucesso');
            } catch (error) {
                toast.error('Error deleting coupon:', error);
            }
        }
    };

    const closeModal = () => {
        setEditingCoupon(null);
        setModal(false);
        setNewCouponName('');
        setNewCouponAvailability('');
        setNewCouponCourses([]);
        setNewCouponType('');
        setNewCouponPrice('');
        setNewCouponPercentage('');
        setNewCouponExpirationDate('');
    }

    const handleEditModal = (coupon) => {
        setEditingCoupon(coupon);
        setNewCouponName(coupon.name);
        setNewCouponAvailability(availabilityOptions.find(option => option.value === coupon.availability));
        setNewCouponCourses(coupon.courses ? coupon.courses.map(courseId => courses.find(course => course.value === courseId)) : []);
        setNewCouponType(typeOptions.find(option => option.value === coupon.type));
        setNewCouponPrice(coupon.price || '');
        setNewCouponPercentage(coupon.percentage || '');
        setNewCouponExpirationDate(new Date(coupon.expiration).toISOString().split('T')[0]);
        setNewCouponUsageLimit(usageLimitOptions.find(option => option.value === coupon.usageLimit));
        setModal(true);
    }

    const handleCouponTypeChange = (selectedType) => {
        setNewCouponType(selectedType);
        setNewCouponPrice('');
        setNewCouponPercentage('');
    };

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#2a2940',
            minHeight: '48px',
            borderColor: '#6753B41A',
            borderWidth: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            color: '#9ca3af', // Custom placeholder color
            padding: '0px 4px'
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'secondary', // Custom text color (example: primary)
            padding: '0px 4px'
        }),
        input: (styles) => ({
            ...styles,
            color: 'secondary', // Custom search input text color
            padding: '0px 4px'
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the dropdown arrow
            '&:hover': {
                color: '#6753B4' // Custom hover color for the dropdown arrow
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the clear "X" button
            '&:hover': {
                color: '#6753B4' // Custom hover color for the clear "X" button
            }
        }),
        menu: (styles) => ({
            ...styles,
            borderRadius: '0.25rem',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#ebf8ff' : isSelected ? '#bee3f8' : undefined,
                color: '#1a202c', // Gray-800
                cursor: 'pointer',
                '&:active': {
                    backgroundColor: '#bee3f8',
                },
            };
        },
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#6753B41A', // Background color of selected items
            borderRadius: '0.25rem',
            display: 'flex',
            alignItems: 'center',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'secondary', // Text color of selected items
            padding: '3px 6px',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'secondary', // Color of the remove icon
            padding: '3px',
            '&:hover': {
                backgroundColor: '#1a202c',
                color: '#6753B4' // Hover color of the remove icon
            }
        })
    };

    return (
        <div className="lg:py-8 containerNavbar">
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/dashboard')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Cupões</h1>
            <div>
                <button onClick={() => setModal(true)} className={`transition duration-500 ease-in-out rounded max-sm:w-full mb-2 text-center bg-primary hover:bg-primary-hover text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]`}>
                    Adicionar Cupão
                </button>
            </div>

            <div className="my-10 overflow-x-auto rounded-[16px] border border-[#A892FF33]">
                <table className="table w-full bg-[#FFFFFF1A] table-auto">
                    <thead className="text-sm sm:text-base text-textHover bg-primary-hover">
                        <tr>
                            <th className="px-2 py-2 border-r border-primary">Cupão</th>
                            <th className="px-2 py-2 border-r border-primary">Disponibilidade</th>
                            <th className="px-2 py-2 border-r border-primary">Tipo</th>
                            <th className="px-2 py-2 border-r border-primary">Preço</th>
                            <th className="px-2 py-2 border-r border-primary">Percentagem</th>
                            <th className="px-2 py-2 border-r border-primary">Limite de Uso</th>
                            <th className="px-2 py-2 border-r border-primary">Expiração</th>
                            <th className="px-2 py-2 border-r border-primary">Usos</th>
                            <th className="w-24 px-1 py-2">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons.map((coupon) => (
                            <tr key={coupon._id} className="border border-[#A892FF33] text-secondary text-xs sm:text-sm text-center">
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{coupon.name}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{getLabelFromOptions(availabilityOptions, coupon.availability)}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{getLabelFromOptions(typeOptions, coupon.type)}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{coupon.price ? '€' + coupon.price : "-"}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{coupon.percentage ? coupon.percentage + "%" : "-"}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{getLabelFromOptions(usageLimitOptions, coupon.usageLimit)}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{new Date(coupon.expiration).toLocaleDateString('pt-PT')}</td>
                                <td className="px-2 py-2 border-r border-[#A892FF33]">{coupon.usedBy.length}</td>
                                <td className="flex justify-center flex-shrink-0 px-2 py-2">
                                    <button
                                        onClick={() => handleEditModal(coupon)}
                                        className="p-2 mr-3 text-sm font-bold text-white rounded bg-primary hover:bg-primary-hover"
                                    >
                                        <MdEdit />
                                    </button>
                                    <button
                                        onClick={() => handleDeleteCoupon(coupon._id)}
                                        className="p-2 text-sm font-bold text-white bg-red-500 rounded hover:bg-red-600"
                                    >
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={modal} appElement={document.getElementById('root')} className="fixed inset-0 flex items-center justify-center" overlayClassName="fixed inset-0 bg-[rgba(0,0,0,0.35)]">
                <div className="absolute w-full h-full"></div>
                <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-12 w-[90%] sm:w-[30rem] bg-[#1c1c32] py-7 rounded-[16px]">
                    <div>
                        <h1 className="text-lg font-bold text-white">{editingCoupon ? 'Editar Cupão' : 'Adicionar Cupão'}</h1>
                    </div>
                    <div className='w-11/12 mt-5 space-y-3'>
                        <input
                            type="text"
                            value={newCouponName}
                            onChange={(e) => setNewCouponName(e.target.value)}
                            className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                            placeholder="Cupão"
                        />
                        <Select
                            className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none  text-secondary placeholder-gray-400 focus:outline-primary"
                            id="coupon-availability"
                            value={newCouponAvailability}
                            onChange={(e) => setNewCouponAvailability(e)}
                            options={availabilityOptions}
                            styles={customStyles}
                            placeholder="Disponibilidade"
                        />
                        {newCouponAvailability && newCouponAvailability.value === 'specific' &&
                            <Select
                                isMulti
                                options={courses}
                                value={newCouponCourses}
                                onChange={(selectedOptions) => setNewCouponCourses(selectedOptions)}
                                className="w-full text-xs font-normal h-auto tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                styles={customStyles}
                                placeholder="Cursos"
                            />}
                        <Select
                            className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none  text-secondary placeholder-gray-400 focus:outline-primary"
                            id="coupon-type"
                            value={newCouponType}
                            onChange={handleCouponTypeChange}
                            options={typeOptions}
                            styles={customStyles}
                            placeholder="Tipo"
                        />
                        {newCouponType && newCouponType.value === 'price' &&
                            <input
                                type="number"
                                value={newCouponPrice}
                                onChange={(e) => setNewCouponPrice(e.target.value)}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                placeholder="Preço"
                            />}
                        {newCouponType && newCouponType.value === 'percentage' &&
                            <input
                                type="number"
                                value={newCouponPercentage}
                                onChange={(e) => setNewCouponPercentage(e.target.value)}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                placeholder="Percentagem"
                            />}
                        <Select
                            className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none  text-secondary placeholder-gray-400 focus:outline-primary"
                            id="coupon-usage-limit"
                            value={newCouponUsageLimit}
                            onChange={(e) => setNewCouponUsageLimit(e)}
                            options={usageLimitOptions}
                            styles={customStyles}
                            placeholder="Limite de Uso"
                        />
                        <input
                            type="date"
                            value={newCouponExpirationDate}
                            onChange={(e) => setNewCouponExpirationDate(e.target.value)}
                            className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                            placeholder="Data expiração"
                        />
                    </div>
                    <div className='mt-5'>
                        <button className='hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]' onClick={() => handleCreateOrUpdateCoupon()}>{editingCoupon ? 'Editar' : 'Adicionar'}</button>
                    </div>
                    <button className="absolute top-3 right-3" onClick={() => closeModal()}>
                        <IoCloseSharp size={24} className="text-red-500 hover:text-red-700" />
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default CouponsPage;