import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../context/language.context';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import toast from 'react-hot-toast';
import { GoArrowLeft } from "react-icons/go";

const LessonAdminPage = () => {
    const location = useLocation();
    const { courseId } = location.state || {};
    const { strings } = useContext(LanguageContext);
    const { lessonId } = useParams();
    const lessonNameRef = useRef('');
    const lessonSlugRef = useRef('');
    const [lessonContent, setLessonContent] = useState('');
    const editorContentRef = useRef('');
    const [lesson, setLesson] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/lesson/getLesson/${lessonId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
                });
                lessonNameRef.current = response.data.name;
                lessonSlugRef.current = response.data.slug;
                setLessonContent(response.data.content);
                setLesson(response.data);
                editorContentRef.current = response.data.content;
            } catch (error) {
                console.error('Error fetching lesson:', error);
            }
        }

        fetchLesson();
    }, [lessonId]);

    const handleNameChange = (e) => {
        lessonNameRef.current = e.target.value;
    };

    const handleSlugChange = (e) => {
        lessonSlugRef.current = e.target.value;
    };

    const handleContentChange = (content) => {
        editorContentRef.current = content;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (lessonNameRef.current === '') {
            toast.error('Lesson name cannot be empty');
            return;
        }

        if (lessonSlugRef.current === '') {
            toast.error('Lesson slug cannot be empty');
            return;
        }

        let editorContent = editorContentRef.current;

        // Create a temporary DOM element and set its innerHTML
        const tempElement = document.createElement('div');
        tempElement.innerHTML = editorContent;

        // Find all video tags and update them
        const videos = tempElement.querySelectorAll('video');
        videos.forEach(video => {
            video.setAttribute('controlsList', 'nodownload');
            video.setAttribute('oncontextmenu', 'return false;');
        });

        // Convert the updated HTML back to a string
        const updatedContent = tempElement.innerHTML;

        const updatedLesson = {
            name: lessonNameRef.current,
            slug: lessonSlugRef.current,
            content: updatedContent
        };

        toast.promise(
            axios.patch(`${process.env.REACT_APP_SERVER_URL}/lesson/updateLesson/${lessonId}`, updatedLesson, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            }),
            {
                loading: <b>{strings.toast.toastLoading}</b>,
                success: <b>{strings.toast.toastSuccess}</b>,
                error: <b>{strings.toast.toastError}</b>,
            }
        );
    };

    const handlePreview = () => {
        navigate(`/lesson-preview-admin/${courseId}/${lessonId}`);
    };

    return (
        <div className='mb-20 lg:py-8 containerNavbar'>
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate(`/courses-admin/${courseId}?view=modules&module=${lesson?.module}`)}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <form onSubmit={handleSubmit} className="space-y-8">
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 w-full md:w-1/2 lg:w-3/5'>
                        <div className='flex flex-col w-full md:w-1/2'>
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Name</label>
                            <input
                                type="text"
                                defaultValue={lessonNameRef.current}
                                placeholder="Enter lesson name"
                                onChange={handleNameChange}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                            />
                        </div>
                        <div className='flex flex-col w-full md:w-1/2'>
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Slug</label>
                            <input
                                type="text"
                                defaultValue={lessonSlugRef.current}
                                placeholder="Enter lesson slug"
                                onChange={handleSlugChange}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                            />
                        </div>
                    </div>
                    <div className='flex self-end ml-2 space-x-4'>
                        <button type="button" onClick={handlePreview} className="self-end hidden md:flex hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 py-4 px-6 text-white hover:text-textHover tracking-[2px]">
                            Preview
                        </button>
                        <button type="submit" className="self-end flex-shrink-0 hidden md:flex hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 py-4 px-6 text-white hover:text-textHover tracking-[2px]">
                            Save Changes
                        </button>
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Content</label>
                    <JoditEditor
                        value={lessonContent}
                        config={{
                            readonly: false,
                            toolbar: true,
                            theme: "dark",
                            extraButtons: [{
                                name: 'loom',
                                iconURL: 'https://img.icons8.com/material-rounded/24/FFFFFF/video.png',
                                exec: (editor) => {
                                    const url = prompt('Enter Loom video URL:');
                                    if (url) {
                                        const embedUrl = url.replace('loom.com/share', 'loom.com/embed');
                                        editor.selection.insertHTML(`<iframe src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);
                                    }
                                },
                                tooltip: 'Insert Loom Video'
                            }],
                        }}
                        onBlur={handleContentChange}
                        onChange={(newContent) => { handleContentChange(newContent) }}

                    />
                </div>

                <button type="submit" className="flex md:hidden hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px] float-end">
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default LessonAdminPage;
