import React, { useState, useEffect, useContext } from 'react';
import TestimonialSlider from '../../components/TestimonialSlider/TestimonialSlider';
import axios from 'axios';
import { HiArrowLongRight } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context';
import { LanguageContext } from '../../context/language.context';

const HomePage = () => {
  const { user } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const { strings } = useContext(LanguageContext);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/course/getAll`);
        setCourses(response.data.filter(course => course.status === "active"))
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const difficultyOptions = {
    beginner: 'Fácil',
    intermediate: 'Médio',
    advanced: 'Difícil',
    'ultra-advanced': 'Ultra-Avançado'
};

  return (
    <>
      <div className="containerNavbar">

        {/* Hero Section */}
        <div className="mt-[30px] sm:mt-[50px] lg:mt-[113px] flex flex-row relative">
          <div className='z-10 lg:w-[60%] text-left text-white'>
            <h1 className="font-bold text-[42px] leading-[54px] sm:text-[68px] sm:leading-[88px] gradient-hero h1-border-bottom">Eleve o seu conhecimento</h1>
            {/* <div className='h-[1px] w-full bg-gradient-to-r from-primary to-[#3D2E7700]' /> */}
            <p className="mt-8 mb-10 text-[16px] sm:text-[18px] leading-6 font-bold text-[#E6E6E6] md:w-[85%] lg:w-[70%]">Junte-se a <span className='text-primary-hover'>uma comunidade de aprendizes apaixonados e especialistas da indústria</span> dedicados a moldar o futuro da tecnologia.<br /><br />Pronto para libertar o seu potencial? Explore os nossos cursos de desenvolvimento e comece a sua jornada hoje mesmo.</p>
            <button onClick={() => navigate('/allcourses')} className="px-6 py-4 text-[12px] leading-4 tracking-[2px] uppercase bg-dionamiteOrange rounded text-[#381708] border-[#FF5C00] border font-bold">Ver todos os cursos</button>
          </div>

          <div className='absolute max-w-[842px] -right-[53px] -top-[113px] hidden lg:flex flex-grow z-0'>
            <img src="/heroImage.png" alt="logo" className="w-full max-w-full select-none" />
          </div>
        </div>


        <img src='jsBigParallax.png' className='h-[311px] sm:h-[435px] md:h-[622px] left-0 top-[90%] sm:top-[865px]' style={{
          transform: `translateY(-${offset * 2.2}px)`,
          position: 'absolute',
          zIndex: '10',
          pointerEvents: 'none',
          userSelect: 'none'
        }} />

        <img src='reactParallax.png' className='h-[105px] sm:h-[147px] md:h-[210px] left-[24%] md:left-[38%] top-[100%] sm:top-[868px]' style={{
          transform: `translateY(-${offset * 0.55}px)`,
          position: 'absolute',
          zIndex: '10',
          pointerEvents: 'none',
          userSelect: 'none'
        }} />

        <img src='jsParallax.png' className='h-[48px] sm:h-[67px] md:h-[96px] right-[27%] md:right-[30%] top-[100%] sm:top-[868px]' style={{
          transform: `translateY(-${offset * 0.4}px)`,
          position: 'absolute',
          zIndex: '10',
          pointerEvents: 'none',
          userSelect: 'none'
        }} />

        <img src='figmaParallax.png' className='h-[194px] sm:h-[271px] md:h-[388px] right-0 top-[102%] sm:top-[882px]' style={{
          transform: `translateY(-${offset * 1.15}px)`,
          position: 'absolute',
          zIndex: '10',
          pointerEvents: 'none',
          userSelect: 'none'
        }} />

        <div className='mb-20 mt-60 sm:mt-80'>
          <div className="relative bg-gradient-to-r from-[#FFFFFF4F] to-[#9999994F] rounded-full w-[140px] h-[30px] flex items-center justify-center">
            <span className="relative z-4 bg-[#28283E] text-white rounded-full px-4 py-2 font-light text-[12px] leading-3 tracking-[2px]">
              PORQUÊ NÓS?
            </span>
          </div>

          <h2 className="mb-[71px] font-bold text-[32px] leading-[40px] gradient-hero mt-4">O porquê de aprender com a Dionamite?</h2>

          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-[71px] gap-x-8'>
            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Instrutores Especialistas da Indústria</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Aprenda com profissionais experientes e altamente qualificados, que trazem insights práticos e conhecimentos diretamente das suas experiências.</p>
            </div>
            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Aprendizagem Prática</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Mergulhe em projetos práticos que simulam cenários reais, dotando-o das competências e confiança necessárias para ter sucesso na sua área escolhida.</p>
            </div>
            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Currículo de ponta</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Mantenha-se atualizado com o nosso currículo constantemente renovado, desenhado para refletir as últimas tendências, tecnologias e melhores práticas no desenvolvimento.</p>
            </div>

            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Atenção Personalizada</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Beneficie de turmas pequenas e atenção personalizada dos instrutores, garantindo que recebe o suporte e orientação necessários para se destacar nos seus estudos.</p>
            </div>
            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Abordagem Focada na Carreira</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Receba suporte abrangente na sua carreira, incluindo assistência com o currículo, preparação para entrevistas e acesso à nossa vasta rede de contactos da indústria.</p>
            </div>
            <div className='px-6 learn-border-left'>
              <h3 className='text-primary font-bold text-[18px] leading-6 mb-4'>Histórico Comprovado</h3>
              <p className='text-[#BFBFBF] text-[16px] leading-6'>Junte-se aos milhares de graduados bem-sucedidos que asseguraram carreiras recompensadoras na tecnologia, graças à formação na Dionamite Academy.</p>
            </div>
          </div>

          <div className='learn-bottom-gradient'></div>

        </div>
      </div>


      <div>
        <div className="mx-auto circle">
          <img src='circleTestimonials.png' className='w-[21px]' />
        </div>

        <div className='mt-[73px]'>
          <TestimonialSlider />
        </div>
      </div>


      <div className='dionamiteContainer mt-36'>
        <div className="relative bg-gradient-to-r from-[#FFFFFF4F] to-[#9999994F] rounded-full w-[165px] h-[30px] flex items-center justify-center">
          <span className="relative z-10 bg-[#28283E] text-white rounded-full px-4 py-2 font-light text-[12px] leading-3 tracking-[2px]">
            NOSSOS CURSOS
          </span>
        </div>

        <h2 className="mb-16 font-bold text-[32px] leading-[40px] gradient-hero mt-4">Dê um impulso à sua carreira com o nosso conteúdo!</h2>

        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-8 '>
          {courses.slice(0, 6).map(course =>
            <div key={course?._id} className='flex flex-col bg-[#FFFFFF1A] rounded-[16px] overflow-hidden border border-[#A892FF33] transition duration-500 ease-in-out hover:shadow-card group cursor-pointer'
              onClick={(e) => {
                e.stopPropagation(); // Prevents event from propagating to the parent div
                if (course?.purchased?.some(purchase => purchase.userId === user?._id)) {
                  navigate(`/mycourses/${course?.slug}`)// Navigate to course if already purchased
                } else {
                  navigate(`/course/${course?.slug}`); // Navigate to checkout if not purchased
                }
              }}>
              <img src={course?.image} alt={`Image of ${course?.title}`} className='h-[180px] object-cover' />
              <div className='flex flex-col justify-between h-full'>
                <div className='px-4 pt-4'>
                  <p className='text-secondary font-bold text-[20px] leading-7'>{course?.title}</p>
                  <p className='text-primary font-medium text-[14px] leading-6 mb-4'>{difficultyOptions[course?.difficulty]}</p>
                  <p className='text-white text-[14px] leading-6 font-medium line-clamp-4'>{course?.description}</p>
                </div>
                <div>
                  <button className='flex text-left items-center mt-10 py-7 font-bold space-x-3 px-4 text-white uppercase transition duration-500 ease-in-out text-[12px] leading-4 tracking-[2px] border-t border-[#A892FF1A] w-full justify-between group-hover:bg-primary-hover group-hover:text-textHover'>
                    <span>{course.purchased.some(purchase => purchase.userId === user?._id) ? strings.courses.myCourses.seeCourse : `Compre este curso por ${course?.price}€`}</span>
                    <HiArrowLongRight className='size-6 min-w-6' />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='flex justify-center mt-10'>
          <button className='font-bold uppercase text-[12px] leading-4 tracking-[2px] text-[#381708] py-4 px-6 bg-[#FDAA7B] rounded border border-[#FF5C00]' onClick={() => navigate('/allcourses')}>
            Vê todos os cursos!
          </button>
        </div>

      </div>

      <div className='dionamiteContainer mt-[120px]'>
        <h2 className="mb-14 font-bold text-[32px] leading-[40px] gradient-hero text-center">Junte-se a esta incrível comunidade de aprendizes!</h2>
        <div className='grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-5 gap-x-5 sm:gap-x-[33px] h-[820px] overflow-hidden'>

          <div className='flex-col items-center justify-start hidden mt-16 space-y-5 sm:space-y-8 lg:flex h-fit'>
            <img src={'learners/imagem1.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem6.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem11.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
          </div>

          <div className='flex flex-col items-center justify-start mt-8 space-y-5 sm:space-y-8'>
            <img src={'learners/imagem2.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem7.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem12.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
          </div>

          <div className='flex flex-col items-center justify-start space-y-5 sm:space-y-8'>
            <img src={'learners/imagem3.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem8.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem13.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
          </div>

          <div className='flex flex-col items-center justify-start mt-8 space-y-5 sm:space-y-8'>
            <img src={'learners/imagem4.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem9.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem14.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
          </div>

          <div className='flex flex-col items-center justify-start mt-16 space-y-8'>
            <img src={'learners/imagem5.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem10.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
            <img src={'learners/imagem15.png'} alt={'Learners'} className='rounded-lg h-[260px] object-cover' />
          </div>


        </div>
      </div>




    </>
  );
}

export default HomePage;
