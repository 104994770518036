import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/auth.context';
import { LanguageContext } from "../../context/language.context";
import toast from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51OMSxBEMfCrRFy2WL1NyIRVd3YrbqU6XaEEpD7v4HVR6mb0RedO0hMrJVY8XGLRRrTjiggMlTV4VsMF2L8rbojaV00LrPzVYd2');

function Completion(props) {
    const { user } = useContext(AuthContext);
    const { strings } = useContext(LanguageContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPaymentIntentAndPurchaseCourse = async () => {
            try {
                const paymentIntentId = queryParams.get('payment_intent');
                if (!paymentIntentId) {
                    toast.error('Payment verification failed.');
                    return;
                }

                // Assuming the actual confirmation and retrieval of courseId happens backend-side
                const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/stripe/verify-and-purchase`, {
                    paymentIntentId,
                    userId: user._id,
                }, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
                });

                if (response.data.success) {
                    toast.success('Course successfully purchased.');
                } else {
                    toast.error(response.data.message || 'Payment verification failed.');
                }
            } catch (error) {
                console.error("Error during payment verification and course purchase:", error);
                toast.error('Internal error during payment verification.');
            }
        };

        fetchPaymentIntentAndPurchaseCourse();
    }, [user._id, queryParams]);

    return (
        <div className="w-3/4 py-8 mx-auto containerNavbar">
            <div className="flex items-center justify-center mt-12 mb-12">
                <h1 className="text-2xl font-bold text-white sm:text-4xl">{strings.purchase.thanks}<span className="ml-1 text-2xl font-bold text-primary sm:text-4xl">!</span></h1>
            </div>
            <div className='flex justify-center'>
                <button
                    onClick={() => navigate("/mycourses")}
                    className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                >
                    Ir para cursos comprados
                </button>
            </div>
        </div>
    );
}

export default Completion;