import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
    const sliderRef1 = useRef(null); // Reference for the first slider
    const sliderRef2 = useRef(null); // Reference for the second slider
    const [activeSlide1, setActiveSlide1] = useState(0);
    const [activeSlide2, setActiveSlide2] = useState(0);

    const adjustSlideMargins = (sliderRef) => {
        if (sliderRef.current) {
            const sliderHeight = sliderRef.current.clientHeight; // Height of the slider container
            const slides = sliderRef.current.querySelectorAll('.slick-slide'); // Node list of slides

            slides.forEach(slide => {
                const slideHeight = slide.clientHeight; // Height of the current slide
                const newMarginTop = (sliderHeight - slideHeight) / 2; // Calculate new margin top
                slide.style.marginTop = `${newMarginTop}px`; // Apply the margin to the slide
            });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            adjustSlideMargins(sliderRef1);
            adjustSlideMargins(sliderRef2);
        };
    
        const imagesLoadCheck = (sliderRef) => {
            const images = sliderRef.current.querySelectorAll('img');
            const totalImages = images.length;
            let loadedImages = 0;
    
            images.forEach(image => {
                if (image.complete) {
                    loadedImages += 1;
                } else {
                    image.onload = () => {
                        loadedImages += 1;
                        if (loadedImages === totalImages) {
                            adjustSlideMargins(sliderRef);
                        }
                    };
                }
            });
    
            // If there are no images, or all are already loaded, adjust margins immediately
            if (totalImages === 0 || loadedImages === totalImages) {
                adjustSlideMargins(sliderRef);
            }
        };
    
        imagesLoadCheck(sliderRef1);
        imagesLoadCheck(sliderRef2);
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const testimonials1 = [
        {
            name: "Anastasiia Husieva - Junior Software Developer",
            image: "anastasiia.jpeg",
            text: "Estou extremamente grata ao Diogo e a toda a Dionamite Academy pelo apoio e orientação excecionais. O conhecimento que adquiri foi inestimável, e as dicas especializadas do Diogo sobre procura de emprego, preparação para entrevistas e apresentação pessoal foram transformadoras."
        },
        {
            name: "Vanya Markova - Full Stack Web Developer",
            image: "vanyamarkova.jpeg",
            text: "O Diogo vai além para fornecer aos seus alunos uma abundância de recursos. Para além da sua experiência, é incrivelmente acessível, amigável e traz uma sensação de diversão ao ambiente de aprendizagem."
        },
        {
            name: "Michael L. - Digital Project Management",
            image: "pedro.png",
            text: "Enrolling in Dionamite Academy's digital project management course was one of the best decisions I've made for my career. The curriculum was comprehensive, covering everything from agile methodologies to stakeholder management. The instructors were experts in their field, and the career support was everything I hoped for and more."
        },
        {
            name: "Alex K. - App Design Mastery Graduate",
            image: "joana.png",
            text: "I can't recommend Dionamite Academy enough! The app design course exceeded my expectations, covering everything from UI/UX principles to prototyping tools."
        },
    ];

    const testimonials2 = [
        {
            name: "Michael L. - Digital Project Management",
            image: "pedro.png",
            text: "Enrolling in Dionamite Academy's digital project management course was one of the best decisions I've made for my career. The curriculum was comprehensive, covering everything from agile methodologies to stakeholder management. The instructors were experts in their field, and the career support was everything I hoped for and more."
        },
        {
            name: "Alex K. - App Design Mastery Graduate",
            image: "joana.png",
            text: "I can't recommend Dionamite Academy enough! The app design course exceeded my expectations, covering everything from UI/UX principles to prototyping tools."
        },
        {
            name: "Emily T. - Web Course Graduate",
            image: "emily.png",
            text: "Dionamite Academy transformed my passion for coding into a career. The instructors were incredibly knowledgeable and supportive, and the hands-on projects helped me build a solid foundation in web development. Thanks to Dionamite, I landed my dream job as a front-end developer!"
        },
        {
            name: "Alex K. - App Design Mastery Graduate",
            image: "alex.png",
            text: "I can't recommend Dionamite Academy enough! The app design course exceeded my expectations, covering everything from UI/UX principles to prototyping tools. The instructors were top-notch, and the practical assignments challenged me to think creatively."
        }
    ];


    const previousSlide1 = activeSlide1 === 0 ? testimonials1.length - 1 : activeSlide1 - 1;
    const previousSlide2 = activeSlide2 === 0 ? testimonials2.length - 1 : activeSlide2 - 1;

    const settings1 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        /* afterChange: (current) => setActiveSlide1(current), */
        beforeChange: (oldIndex, newIndex) => {
            setActiveSlide1(newIndex);
        },
        centerPadding: '150px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '80px',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '30px',
                }
            }
        ]
    };

    const settings2 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        /* afterChange: (current) => setActiveSlide2(current), */
        beforeChange: (oldIndex, newIndex) => {
            setActiveSlide2(newIndex);
        },
        centerPadding: '150px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '80px',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '30px',
                }
            }
        ]
    };

    return (
        <div className="relative">
            <div className="brightening-circle"></div>

            <div ref={sliderRef1} className="w-full slider slider1">
                <Slider {...settings1}>
                    {testimonials1.map((testimonial, index) => (
                        <div key={index} className={`px-[13px] sm:px-[26px] slide ${((index === activeSlide1 && window.innerWidth < 1024) || (index === activeSlide1 || index === previousSlide1) && window.innerWidth >= 1024) ? 'active-slide' : 'inactive-slide'}`}>
                            <div className="p-8 rounded-[64px] bg-[#401F7166] flex flex-row gap-x-[15px] border border-textHover max-w-[800px]">
                                <div className='w-full max-w-[50px] md:max-w-[64px] flex flex-col items-center justify-start'>
                                    <img src={testimonial.image} alt={`${testimonial.name} profile`} className="flex flex-grow object-contain w-16 h-16 mb-6 rounded-full" />
                                    <div className='border-left-testimonial' />
                                </div>
                                <div className='w-full space-y-3'>
                                    <span className="mt-2 text-[10px] sm:text-[12px] leading-4 text-primary-hover font-bold tracking-[2px] uppercase">{testimonial.name}</span>
                                    <p className="text-[12px] sm:text-[14px] text-[#E6E6E6] leading-5 sm:leading-6 font-medium">"{testimonial.text}"</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div ref={sliderRef2} className="w-full slider mt-[60px] slider2 hidden md:inline-block">
                <Slider {...settings2}>
                    {testimonials2.map((testimonial, index) => (
                        <div key={index} className={`px-[13px] sm:px-[26px] slide ${((index === activeSlide2 && window.innerWidth < 1024) || (index === activeSlide2 || index === previousSlide2) && window.innerWidth >= 1024) ? 'active-slide' : 'inactive-slide'}`}>
                            <div className="p-8 rounded-[64px] bg-[#401F7166] flex flex-row gap-x-[15px] border border-textHover max-w-[800px]">
                                <div className='w-full max-w-[50px] md:max-w-[64px] flex flex-col items-center justify-start'>
                                    <img src={testimonial.image} alt={`${testimonial.name} profile`} className="flex flex-grow object-contain w-16 h-16 mb-6 rounded-full" />
                                    <div className='border-left-testimonial' />
                                </div>
                                <div className='w-full space-y-3'>
                                    <span className="mt-2 text-[10px] md:text-[12px] leading-4 text-primary-hover font-bold tracking-[2px] uppercase">{testimonial.name}</span>
                                    <p className="text-[12px] md:text-[14px] leading-5 text-[#E6E6E6] md:leading-6 font-medium">"{testimonial.text}"</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialSlider;